<template>
  <div>
    <click-to-edit
      v-if="scope.row.scale"
      v-model="scope.row.scale.plan"
      :number-format="{}"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.plan')"
      placeholder=""
      @input="saveElementPropChange(scope.row, 'scale', {plan:$event})"
    >
    </click-to-edit>
    <div v-else style="color: #b1b1b2;">Сначала необходимо выбрать шкалу</div>
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-scale-plan-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {

    }
  },

  methods: {

  }
}

</script>

<style>

</style>
